import React from "react";

import { formatDate } from "../dates";

interface Props {
  event: Event;
}

export default function EventItem(props: Props) {
  const { event } = props;

  return (
    <article className="event">
      <a href={event.url}>
        <div className="image">
          {event.image_url && (
            <img src={event.image_url} alt={`Plakat til ${event.title}`} />
          )}
        </div>
        <div className="event-date">{formatDate(event.date)}</div>
        <h2>{event.title}</h2>
        <div className="venue">{event.venue}</div>
      </a>
    </article>
  );
}
