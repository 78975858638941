import React from "react";
import Select from "react-select";

import { formatMonth } from "../dates";

export interface FilterState {
  month?: string;
  venue?: string;
  search?: string;
}

interface Props {
  events: Event[];
  filterState: FilterState;
  updateFilterState: (state: FilterState) => void;
}

const selectClassNames = {
  control: () => "select-control"
};

function fuzzymatch(str: string, query: string) {
  const norm = (str: string) => {
    return str
      .toLowerCase()
      .replaceAll("æ", "ae")
      .replaceAll("ø", "o")
      .replaceAll("å", "aa")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };
  const normStr = norm(str);

  const matches = norm(query)
    .split(/\s+/)
    .map((s) => {
      return new RegExp(s).test(normStr);
    });

  return matches.indexOf(false) == -1;
}

function unique<T>(value: T, index: number, self: Array<T>) {
  return self.indexOf(value) === index;
}

function option(value: string) {
  return value && { label: value, value: value };
}

export function filterEvents(events: Event[], state: FilterState) {
  let filtered = events;

  if (state.month) {
    filtered = filtered.filter((e) => formatMonth(e.date) == state.month);
  }

  if (state.venue) {
    filtered = filtered.filter((e) => e.venue == state.venue);
  }

  if (state.search) {
    filtered = filtered.filter((e) =>
      fuzzymatch([e.title, e.venue].join(" "), state.search)
    );
  }

  return filtered;
}

export default function Filter(props: Props) {
  const { events, filterState, updateFilterState } = props;

  const months = events
    .map((e) => e.date)
    .sort()
    .map(formatMonth)
    .filter(unique);
  const venues = events
    .map((e) => e.venue)
    .filter(unique)
    .sort();

  return (
    <div className="filter">
      <Select
        options={months.map(option)}
        className="month-select"
        classNames={selectClassNames}
        aria-label="Velg måned"
        placeholder="Velg måned..."
        isClearable={true}
        value={option(filterState.month)}
        onChange={(v) => updateFilterState({ month: v && v.value })}
      />
      <Select
        options={venues.map(option)}
        className="venue-select"
        classNames={selectClassNames}
        placeholder="Velg sted..."
        aria-label="Velg sted"
        isClearable={true}
        value={option(filterState.venue)}
        onChange={(v) => updateFilterState({ venue: v && v.value })}
      />
      <input
        type="text"
        className="search"
        value={filterState.search}
        placeholder="Søk"
        aria-label="Søk"
        onChange={(v) => updateFilterState({ search: v.target.value })}
      />
    </div>
  );
}
