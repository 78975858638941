const monthNames = [
  "januar",
  "februar",
  "mars",
  "april",
  "mai",
  "juni",
  "juli",
  "august",
  "september",
  "oktober",
  "november",
  "desember"
];

function capitalize(s: string) {
  if (typeof s !== "string") {
    return "";
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function formatMonth(arg: string) {
  const date = new Date(arg);
  return `${capitalize(monthNames[date.getMonth()])} ${date.getFullYear()}`;
}

export function formatDate(arg: string) {
  const date = new Date(arg);
  return `${date.getDate()}. ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`;
}
