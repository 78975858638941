// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
Rails.start();

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Grid overlay
import gridOverlay from "./lib/gridOverlay";
gridOverlay();

// Stimulus
import { Application } from "stimulus";
import ExpandableController from "./controllers/ExpandableController";
import HeaderController from "./controllers/HeaderController";
import SubnavController from "./controllers/SubnavController";
const application = Application.start();
application.register("expandable", ExpandableController);
application.register("header", HeaderController);
application.register("subnav", SubnavController);

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;
