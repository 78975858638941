import React from "react";

import EventItem from "./EventItem";

interface Props {
  events: Event[];
}

export default function Events(props: Props) {
  const { events } = props;

  if (events.length == 0) {
    return (
      <div className="empty">
        <p>Ingen oppføringer matcher ditt søk.</p>
      </div>
    );
  }

  return (
    <div className="events article-row" aria-live="polite">
      {events.map((e) => (
        <EventItem key={e.id} event={e} />
      ))}
    </div>
  );
}
