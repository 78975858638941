import { Controller } from "stimulus";

export default class extends Controller {
  anchorTarget: HTMLAnchorElement;

  static get targets() {
    return ["anchor"];
  }

  initialize() {
    if (
      window.location.hash &&
      window.location.hash.substr(1) == this.anchorTarget.name
    ) {
      this.element.classList.add("expanded");
    }
  }

  toggle(evt: Event) {
    evt.preventDefault();
    this.element.classList.toggle("expanded");
  }
}
